import React from 'react'
import Sidebar from '../components/side_bar'
import Fixed_header from '../components/fixed_header'

const Student_profile = () => {
  return (
    <div class="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          {/* ==================================================================== */}
          <Fixed_header />

          {/* =================================================================================== */}
          <div class="nk-content">
          <div class="container">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head">
        <div class="nk-block-head-between flex-wrap gap gap-2 align-items-start">
          <div class="nk-block-head-content">
            <div class="d-flex flex-column flex-md-row align-items-md-center">
              <div class="media media-middle media-huge media-circle">
                <img src="https://react.nioboard.themenio.com/images/avatar/a.jpg" alt="user" class="img-thumbnail"/>
              </div>
              <div class="mt-3 mt-md-0 ms-md-3">
                <h3 class="title mb-1">
                  Florenza Desporte
                </h3>
                <span class="small">
                  Owner &amp; Founder
                </span>
                <ul class="nk-list-option pt-1">
                  <li>
                    <em class="icon ni ni-map-pin">
                    </em>
                    <span class="small">
                      California, United States
                    </span>
                  </li>
                  <li>
                    <em class="icon ni ni-building">
                    </em>
                    <span class="small">
                      Softnio
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="nk-block-head-content">
            <div class="d-flex gap g-3">
              <div class="gap-col">
                <div class="box-dotted py-2">
                  <div class="d-flex align-items-center">
                    <div class="h4 mb-0">
                      2574k
                    </div>
                    <span class="change up ms-1 small">
                      <em class="icon ni ni-arrow-down">
                      </em>
                    </span>
                  </div>
                  <div class="smaller">
                    Followers
                  </div>
                </div>
              </div>
              <div class="gap-col">
                <div class="box-dotted py-2">
                  <div class="d-flex align-items-center">
                    <div class="h4 mb-0">
                      78k
                    </div>
                    <span class="change up ms-1 small">
                      <em class="icon ni ni-arrow-up">
                      </em>
                    </span>
                  </div>
                  <div class="smaller">
                    Following
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="nk-block-head-between flex-wrap gap gap-2">
        <div class="gap-col">
          <div class="nav-pills-border gap g-3 nav nav-pills" role="tablist">
            <div class="nav-item">
              <a role="tab" data-rr-ui-event-key="tabOne" id="react-aria7081768702-52-tab-tabOne"
              aria-controls="react-aria7081768702-52-tabpane-tabOne" aria-selected="true"
              class="nav-link active" tabindex="0" href="#">
                Overview
              </a>
            </div>
            <div class="nav-item">
              <a role="tab" id="react-aria7081768702-52-tab-null" aria-controls="react-aria7081768702-52-tabpane-null"
              tabindex="-1" class="nav-link" href="#">
                Project
              </a>
            </div>
            <div class="nav-item">
              <a role="tab" id="react-aria7081768702-52-tab-null" aria-controls="react-aria7081768702-52-tabpane-null"
              tabindex="-1" class="nav-link" href="#">
                Documents
              </a>
            </div>
            <div class="nav-item">
              <a role="tab" id="react-aria7081768702-52-tab-null" aria-controls="react-aria7081768702-52-tabpane-null"
              tabindex="-1" class="nav-link" href="#">
                Disabled
              </a>
            </div>
          </div>
        </div>
        <div class="gap-col">
          <ul class="d-flex gap g-2">
            <li class="d-none d-md-block">
              <a class="btn btn-soft btn-primary" href="/user-manage/user-edit/uid01">
                <em class="icon ni ni-edit">
                </em>
                <span>
                  Edit Profile
                </span>
              </a>
            </li>
            <li class="d-md-none">
              <a class="btn btn-soft btn-primary btn-icon" href="/user-manage/user-edit/uid01">
                <em class="icon ni ni-edit">
                </em>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="nk-block mt-4">
        <div class="tab-content">
          <div role="tabpanel" id="react-aria7081768702-52-tabpane-tabOne" aria-labelledby="react-aria7081768702-52-tab-tabOne"
          class="fade tab-pane active show">
            <div class="card-gutter-md card">
              <div class="card-row card-row-lg col-sep col-sep-lg">
                <div class="card-aside">
                  <div class="card-body">
                    <div class="bio-block">
                      <h4 class="bio-block-title">
                        Details
                      </h4>
                      <ul class="list-group list-group-borderless small">
                        <li class="list-group-item">
                          <span class="title fw-medium w-40 d-inline-block">
                            Account ID:
                          </span>
                          <span class="text">
                            45453423
                          </span>
                        </li>
                        <li class="list-group-item">
                          <span class="title fw-medium w-40 d-inline-block">
                            Full Name:
                          </span>
                          <span class="text">
                            Florenza Desporte
                          </span>
                        </li>
                        <li class="list-group-item">
                          <span class="title fw-medium w-40 d-inline-block">
                            Email:
                          </span>
                          <span class="text">
                            florenza@gmail.com
                          </span>
                        </li>
                        <li class="list-group-item">
                          <span class="title fw-medium w-40 d-inline-block">
                            Address:
                          </span>
                          <span class="text">
                            California, United States
                          </span>
                        </li>
                        <li class="list-group-item">
                          <span class="title fw-medium w-40 d-inline-block">
                            Joining Date
                          </span>
                          <span class="text">
                            2022/04/25
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div class="bio-block">
                      <h4 class="bio-block-title mb-2">
                        Skills
                      </h4>
                      <ul class="d-flex flex-wrap gap gx-1">
                        <li>
                          <a class="badge text-bg-secondary-soft" href="/user-manage/user-profile">
                            Photoshop
                          </a>
                        </li>
                        <li>
                          <a class="badge text-bg-secondary-soft" href="/user-manage/user-profile">
                            illustrator
                          </a>
                        </li>
                        <li>
                          <a class="badge text-bg-secondary-soft" href="/user-manage/user-profile">
                            HTML
                          </a>
                        </li>
                        <li>
                          <a class="badge text-bg-secondary-soft" href="/user-manage/user-profile">
                            CSS
                          </a>
                        </li>
                        <li>
                          <a class="badge text-bg-secondary-soft" href="/user-manage/user-profile">
                            Javascript
                          </a>
                        </li>
                        <li>
                          <a class="badge text-bg-secondary-soft" href="/user-manage/user-profile">
                            React
                          </a>
                        </li>
                        <li>
                          <a class="badge text-bg-secondary-soft" href="/user-manage/user-profile">
                            Vue
                          </a>
                        </li>
                        <li>
                          <a class="badge text-bg-secondary-soft" href="/user-manage/user-profile">
                            Angular
                          </a>
                        </li>
                        <li>
                          <a class="badge text-bg-secondary-soft" href="/user-manage/user-profile">
                            Python
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="bio-block">
                      <h4 class="bio-block-title mb-3">
                        Social
                      </h4>
                      <ul class="d-flex flex-wrap gap g-2">
                        <li>
                          <a class="media media-middle media-sm text-bg-dark" href="/softnio">
                            <em class="icon ni ni-github-circle">
                            </em>
                          </a>
                        </li>
                        <li>
                          <a class="media media-middle media-sm text-bg-danger" href="/softnio">
                            <em class="icon ni ni-dribbble">
                            </em>
                          </a>
                        </li>
                        <li>
                          <a class="media media-middle media-sm text-bg-info" href="/softnio">
                            <em class="icon ni ni-twitter">
                            </em>
                          </a>
                        </li>
                        <li>
                          <a class="media media-middle media-sm text-bg-pink" href="/softnio">
                            <em class="icon ni ni-linkedin">
                            </em>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="card-content col-sep">
                  <div class="card-body">
                    <div class="bio-block">
                      <h4 class="bio-block-title">
                        About Me
                      </h4>
                      <p>
                        I code and design websites worldwide. Mauris varius tellus vitae tristique
                        sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non
                        pharetra lacus erat ac nulla.
                      </p>
                      <p>
                        Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget
                        euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis
                        parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque
                        sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?
                      </p>
                      <div class="g-gs pt-2 row">
                        <div class="col-lg-6">
                          <div class="small">
                            Designation:
                          </div>
                          <h5 class="small">
                            Frontend Developer
                          </h5>
                        </div>
                        <div class="col-lg-6">
                          <div class="small">
                            Website:
                          </div>
                          <h5 class="small">
                            www.softnio.com
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="bio-block">
                      <h4 class="bio-block-title">
                        Recent Activity
                      </h4>
                      <ul class="nk-schedule mt-4">
                        <li class="nk-schedule-item">
                          <div class="nk-schedule-item-inner">
                            <div class="nk-schedule-symbol active">
                            </div>
                            <div class="nk-schedule-content">
                              <span class="smaller">
                                2:12 PM
                              </span>
                              <div class="h6">
                                Added 3 New Images
                              </div>
                              <ul class="d-flex flex-wrap gap g-2 pt-2">
                                <li>
                                  <div class="media media-middle media-xxl">
                                    <img src="/images/product/a.jpg" alt="product image" class="img-thumbnail"/>
                                  </div>
                                </li>
                                <li>
                                  <div class="media media-middle media-xxl">
                                    <img src="/images/product/b.jpg" alt="product image" class="img-thumbnail"/>
                                  </div>
                                </li>
                                <li>
                                  <div class="media media-middle media-xxl">
                                    <img src="/images/product/c.jpg" alt="product image" class="img-thumbnail"/>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li class="nk-schedule-item">
                          <div class="nk-schedule-item-inner">
                            <div class="nk-schedule-symbol active">
                            </div>
                            <div class="nk-schedule-content">
                              <span class="smaller">
                                4:23 PM
                              </span>
                              <div class="h6">
                                Invitation for creative designs pattern
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="nk-schedule-item">
                          <div class="nk-schedule-item-inner">
                            <div class="nk-schedule-symbol active">
                            </div>
                            <div class="nk-schedule-content nk-schedule-content-no-border">
                              <span class="smaller">
                                10:30 PM
                              </span>
                              <div class="h6">
                                Task report - uploaded weekly reports
                              </div>
                              <div class="list-group-dotted mt-3">
                                <div class="list-group-wrap">
                                  <div class="p-3">
                                    <div class="media-group">
                                      <div class="media media-middle rounded-0">
                                        <img src="/images/icon/file-type-pdf.svg" alt="icon" class=""/>
                                      </div>
                                      <div class="media-text ms-1">
                                        <a href="#download" class="title">
                                          Modern Designs Pattern
                                        </a>
                                        <span class="text smaller">
                                          1.6 mb
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="p-3">
                                    <div class="media-group">
                                      <div class="media media-middle rounded-0">
                                        <img src="/images/icon/file-type-doc.svg" alt="icon" class=""/>
                                      </div>
                                      <div class="media-text ms-1">
                                        <a href="#download" class="title">
                                          cPanel Upload Guidelines
                                        </a>
                                        <span class="text smaller">
                                          18 kb
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="p-3">
                                    <div class="media-group">
                                      <div class="media media-middle rounded-0">
                                        <img src="/images/icon/file-type-code.svg" alt="icon" class=""/>
                                      </div>
                                      <div class="media-text ms-1">
                                        <a href="#download" class="title">
                                          Weekly Finance Reports
                                        </a>
                                        <span class="text smaller">
                                          10 mb
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="nk-schedule-item">
                          <div class="nk-schedule-item-inner">
                            <div class="nk-schedule-symbol active">
                            </div>
                            <div class="nk-schedule-content nk-schedule-content-no-border flex-grow-1">
                              <span class="smaller">
                                5:05 PM
                              </span>
                              <div class="h6">
                                You have received a new order
                              </div>
                              <div role="alert" class="fade mt-2 alert alert-info show">
                                <div class="d-flex">
                                  <em class="opacity-75 icon ni ni-file-code icon-lg">
                                  </em>
                                  <div class="ms-2 d-flex flex-wrap flex-grow-1 justify-content-between">
                                    <div>
                                      <h6 class="alert-heading mb-0">
                                        Business Template - UI/UX design
                                      </h6>
                                      <span class="smaller">
                                        Shared information with your team to understand and contribute to your
                                        project.
                                      </span>
                                    </div>
                                    <ul class="d-flex gap g-2 pt-1">
                                      <li>
                                        <button type="button" class="btn btn-info btn-md">
                                          <em class="icon ni ni-download">
                                          </em>
                                          <span>
                                            Download
                                          </span>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
            </div>
            </div>
            </div>
            
      
    </div>
  )
}

export default Student_profile
