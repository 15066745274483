import React from 'react';
import Sidebar from '../components/side_bar';
import Fixed_header from '../components/fixed_header';
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Food_storage = () => {
  return (
    <div class="nk-app-root">
    <div className='nk-main'>
    <Sidebar/>
    <div className='nk-wrap'>
{/* ==================================================================== */}
<Fixed_header/>


{/* =================================================================================== */}
<div class="nk-content">
  <div class="container">
  <div class="nk-content-inner">
  <div class="nk-content-body">
    <div class="nk-block-head">
      <div class="nk-block-head-between flex-wrap gap g-2">
        <div class="nk-block-head-content">
          <h2 class="nk-block-title">
            Food storage
          </h2>
         
        </div>
        <div class="nk-block-head-content">
          <ul class="d-flex">
            <li>
              <a href="https://html.nioboard.themenio.com/ecommerce/add-category.html"
              class="btn btn-primary btn-md d-md-none">
                <em class="icon ni ni-plus">
                </em>
                <span>
                  Add
                </span>
              </a>
            </li>
            <li>
              <a href="https://html.nioboard.themenio.com/ecommerce/add-category.html"
              class="btn btn-primary d-none d-md-inline-flex">
                <em class="icon ni ni-plus">
                </em>
                <span>
                  Order Grocery
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="nk-block">
      <div class="card data-table-card">
        <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
          
          <div class="dataTable-container table-responsive">
            <table class="datatable-init table dataTable-table" data-nk-container="table-responsive">
              <thead class="table-light">
                <tr>
                  
                  <th class="tb-col" data-sortable="" style={{width: ' 65.365%'}}>
                    <a href="#" class="dataTable-sorter">
                      <span class="overline-title">
                        Item Name
                      </span>
                    </a>
                  </th>
                  <th class="tb-col tb-col-md" data-sortable="" style={{width: ' 9.93209%'}}>
                    <a href="#" class="dataTable-sorter">
                      <span class="overline-title">
                        type
                      </span>
                    </a>
                  </th>
                  <th class="tb-col tb-col-sm" data-sortable="" style={{width: ' 10.8659%'}}>
                    <a href="#" class="dataTable-sorter">
                      <span class="overline-title">
                        Count
                      </span>
                    </a>
                  </th>
                  <th class="tb-col tb-col-end" data-sortable="false" style={{width: ' 9.76231%'}}>
                    <span class="overline-title">
                      action
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/f.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html"
                        class="title">
                          Chawal
                        </a>
                        <div class="text smaller d-none d-sm-block">
                          Order from Aishsh kirana store
                        </div>
                        <div class="d-sm-none">
                          <span>
                            125
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span class="badge text-bg-primary-soft">
                      Main
                    </span>
                  </td>
                  <td class="tb-col tb-col-sm">
                    <span>
                      125
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                  <Link to="/store_profile" >Order More</Link>
                  </td>
                </tr>
                
                <tr>
                  
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/h.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html"
                        class="title">
                          Headphones
                        </a>
                        <div class="text smaller d-none d-sm-block">
                          Discover a wide range of Headphone including Razer, Gamdias, Logitech
                          headphone
                        </div>
                        <div class="d-sm-none">
                          <span>
                            0
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span class="badge text-bg-info-soft">
                      Sub
                    </span>
                  </td>
                  <td class="tb-col tb-col-sm">
                    <span class="badge text-bg-danger-soft">
                      Stock out
                    </span>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-edit">
                                </em>
                                <span>
                                  Edit
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html">
                                <em class="icon ni ni-trash">
                                </em>
                                <span>
                                  Delete
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="https://html.nioboard.themenio.com/ecommerce/categories.html">
                                <em class="icon ni ni-eye">
                                </em>
                                <span>
                                  View Category
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
               
               
              
                
                <tr>
                  
                  <td class="tb-col">
                    <div class="media-group">
                      <div class="media media-middle">
                        <img src="https://html.nioboard.themenio.com/images/product/m.jpg" alt="product"/>
                      </div>
                      <div class="media-text">
                        <a href="https://html.nioboard.themenio.com/ecommerce/edit-category.html"
                        class="title">
                          T-shirts
                        </a>
                        <div class="text smaller d-none d-sm-block">
                          Explore trendy collection of printed &amp; solid t-shirts
                        </div>
                        <div class="d-sm-none">
                          <span>
                            12
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-md">
                    <span class="badge text-bg-info-soft">
                      Sub
                    </span>
                  </td>
                  <td class="tb-col tb-col-sm">
                    <div class="d-flex align-items-center gap g-1">
                      <div class="gap-col">
                        <span class="text-warning">
                          12
                        </span>
                      </div>
                      <div class="gap-col">
                        <span class="badge text-bg-warning-soft">
                          Low
                        </span>
                      </div>
                    </div>
                  </td>
                  <td class="tb-col tb-col-end">
                    <div class="dropdown">
                      <a href="#" class="btn btn-sm btn-icon btn-zoom me-n1" data-bs-toggle="dropdown">
                        <em class="icon ni ni-more-v">
                        </em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                        <div class="dropdown-content py-1">
                          <ul class="link-list link-list-hover-bg-primary link-list-md">
                          
                           
                           
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                
                
              </tbody>
            </table>
          </div>
        
        </div>
      </div>
    </div>
  </div>
</div>
  </div>
</div>
    </div>
    </div>

    </div>
  );
};

export default Food_storage;
