import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import Sidebar from '../components/side_bar';
import Fixed_header from '../components/fixed_header';
import { FaUsers } from "react-icons/fa";
import { BsSuitcaseLg } from "react-icons/bs";
import { FaUserCog } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Store from '../components/store_bar';
import ConnectionCheck from '../components/ConnectionCheck';


const HomePage = () => {
  const [userCount, setUserCount] = useState(null);
  const [jobCount, setJobCount] = useState(null);
  const [workTypes, setWorkTypes] = useState([]);
  const [error, setError] = useState(null);
  const chartRef = useRef(null);
  useEffect(() => {
    const storedUsername = localStorage.getItem('Ashram_id');
    if (!storedUsername) {
        // If username is not available, redirect the user to the login page
        window.location.href = '/login';// Change '/login' to your desired login page URL
    } 
}, []);


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch("https://app.karmchari.in/api/admin/count_user");
        if (!response.ok) {
          throw new Error('Failed to fetch user count');
        }
        const data = await response.json();
        setUserCount(data.userCount);
      } catch (error) {
        console.error('Error fetching user count:', error);
        setError('Failed to fetch user count');
      }
    };

    const fetchJobData = async () => {
      try {
        const response = await fetch("https://app.karmchari.in/api/admin/totle_job");
        if (!response.ok) {
          throw new Error('Failed to fetch job count');
        }
        const data = await response.json();
        setJobCount(data.jobCount);
      } catch (error) {
        console.error('Error fetching job count:', error);
        setError('Failed to fetch job count');
      }
    };

    const fetchWorkTypes = async () => {
      try {
        const response = await fetch("https://app.karmchari.in/api/admin/job_type");
        if (!response.ok) {
          throw new Error('Failed to fetch work types');
        }
        const data = await response.json();
        setWorkTypes(data);
      } catch (error) {
        console.error('Error fetching work types:', error);
        setError('Failed to fetch work types');
      }
    };

    fetchUserData();
    fetchJobData();
    fetchWorkTypes();

    // Cleanup function
    return () => {
      // Any cleanup needed for intervals or other effects
    };
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      offset: 200,
      once: true
    });

    return () => {
      AOS.refresh();
    };
  }, []);

  

  


  return (
    <div class="nk-app-root">
    <ConnectionCheck/>
    <div className='nk-main'>
    <Sidebar/>
    <div className='nk-wrap'>
{/* ==================================================================== */}
<Fixed_header/>


{/* =================================================================================== */}
<div class="nk-content">
  <div class="container-fluid">
    <div className='nk-content-inner'>
      <div className='nk-content-body'>
        <div className='row g-gs'>
        {/* ---------------------------------------------------------------- */}
        <div className='col-sm-6 col-xl-6 col-xxl-3' data-aos="fade-left">
          <div class="card h-100">
  <div class="card-body">
    <div class="card-title-group align-items-start">
      <div class="card-title">
        <h4 class="title">
          Student
        </h4>
      </div>
      <div class="media media-middle media-circle media-sm text-bg-primary-soft">
        <em class="icon icon-md ni ni-user-alt-fill">
        <FaUsers />
        </em>
      </div>
    </div>
    <div class="mt-2 mb-4">

    <div className="amount h1">
  {userCount !== null ? (
    userCount
  ) : (
    <div>Loading...</div>
  )}
</div>

            
      <div class="d-flex align-items-center smaller">
        <div class="change up">
          <em class="icon ni ni-upword-alt-fill">
          </em>
          10.5%
        </div>
        <span class="text-light">
          From last 2 Weeks
        </span>
      </div>
    </div>
    <div class="nk-chart-analytics-session">
      jhb
    </div>
    <div class="chart-label-group justify-content-between mt-1">
      <div class="chart-label chart-label-small">
        <div class="title">
          1 May
        </div>
      </div>
      <div class="chart-label chart-label-small">
        <div class="title">
          8 May
        </div>
      </div>
      <div class="chart-label chart-label-small">
        <div class="title">
          15 May
        </div>
      </div>
    </div>
  </div>
</div>
          </div>
          {/* ---------------------------------------------------------------- */}
          <div className='col-sm-6 col-xl-6 col-xxl-3' data-aos="fade-left" data-aos-duration="900">
          <div class="card h-100">
  <div class="card-body">
    <div class="card-title-group align-items-start">
      <div class="card-title">
        <h4 class="title">
          Staff
        </h4>
      </div>
      <div class="media media-middle media-circle media-sm text-bg-primary-soft">
        <em class="icon icon-md ni ni-user-alt-fill">
        <BsSuitcaseLg />
        </em>
      </div>
    </div>
    <div class="mt-2 mb-4">
      <div class="amount h1">
      {jobCount !== null ? jobCount : 'Loading...'}
      </div>
      <div class="d-flex align-items-center smaller">
        <div class="change up">
          <em class="icon ni ni-upword-alt-fill">
          </em>
          10.5%
        </div>
        <span class="text-light">
          From last 2 Weeks
        </span>
      </div>
    </div>
    <div class="nk-chart-analytics-session">
      jhb
    </div>
    <div class="chart-label-group justify-content-between mt-1">
      <div class="chart-label chart-label-small">
        <div class="title">
          1 May
        </div>
      </div>
      <div class="chart-label chart-label-small">
        <div class="title">
          8 May
        </div>
      </div>
      <div class="chart-label chart-label-small">
        <div class="title">
          15 May
        </div>
      </div>
    </div>
  </div>
</div>
          </div>
          {/* ---------------------------------------------------------------- */}
          <div className='col-sm-6 col-xl-6 col-xxl-3' data-aos="fade-left" data-aos-duration="1100">
          <div class="card h-100">
  <div class="card-body">
    <div class="card-title-group align-items-start">
      <div class="card-title">
        <h4 class="title">
          leaves
        </h4>
      </div>
      <div class="media media-middle media-circle media-sm text-bg-primary-soft">
        <em class="icon icon-md ni ni-user-alt-fill">
        <BsSuitcaseLg />
        </em>
      </div>
    </div>
    <div class="mt-2 mb-4">
      <div class="amount h1">
        25
      </div>
      <div class="d-flex align-items-center smaller">
        <div class="change up">
          <em class="icon ni ni-upword-alt-fill">
          </em>
          10.5%
        </div>
        <span class="text-light">
          From last 2 Weeks
        </span>
      </div>
    </div>
    <div class="nk-chart-analytics-session">
      jhb
    </div>
    <div class="chart-label-group justify-content-between mt-1">
      <div class="chart-label chart-label-small">
        <div class="title">
          1 May
        </div>
      </div>
      <div class="chart-label chart-label-small">
        <div class="title">
          8 May
        </div>
      </div>
      <div class="chart-label chart-label-small">
        <div class="title">
          15 May
        </div>
      </div>
    </div>
  </div>
</div>
          </div>
          {/* ---------------------------------------------------------------- */}
          <div className='col-sm-6 col-xl-6 col-xxl-3' data-aos="fade-left" data-aos-duration="1300">
          <div class="card h-100">
  <div class="card-body">
    <div class="card-title-group align-items-start">
      <div class="card-title">
        <h4 class="title">
          User Need Action
        </h4>
      </div>
      <div class="media media-middle media-circle media-sm text-bg-primary-soft">
        <em class="icon icon-md ni ni-user-alt-fill">
        <FaUserCog />
        </em>
      </div>
    </div>
    <div class="mt-2 mb-4">
      <div class="amount h1">
        65
      </div>
      <div class="d-flex align-items-center smaller">
        <div class="change up">
          <em class="icon ni ni-upword-alt-fill">
          </em>
          10.5%
        </div>
        <span class="text-light">
          From last 2 Weeks
        </span>
      </div>
    </div>
    <div class="nk-chart-analytics-session">
      jhb
    </div>
    <div class="chart-label-group justify-content-between mt-1">
      <div class="chart-label chart-label-small">
        <div class="title">
          1 May
        </div>
      </div>
      <div class="chart-label chart-label-small">
        <div class="title">
          8 May
        </div>
      </div>
      <div class="chart-label chart-label-small">
        <div class="title">
          15 May
        </div>
      </div>
    </div>
  </div>
</div>
          </div>
         {/* --------------------------------------------------------------------------- */}
         {/* ---------------------------------------------------------------- */}
         
         

        

        </div>
      </div>
    </div>
  </div>
</div>
    </div>
    </div>

    </div>
  );
};

export default HomePage;
