import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../components/side_bar';
import Fixed_header from '../components/fixed_header';
import { Link } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Breakfast from './breakfast';
import Kirana_calculation from './kirana_calculation';

const useFetchMonthData = () => {
  const [monthData, setMonthData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMonthData = async () => {
      try {
        const response = await fetch(`https://api.rox-tech.com/ashram/month.php?Ashram_id=${localStorage.getItem('Ashram_id')}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setMonthData(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchMonthData();

    return () => {}; // No cleanup needed
  }, []);

  return { monthData, error };
};

// Custom hook to fetch kirana item data
const useFetchKiranaItem = () => {
  const [kiranaItem, setKiranaItem] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchKiranaItem = async () => {
      try {
        const response = await fetch(`https://api.rox-tech.com/ashram/fatch_kirana_item.php?Ashram_id=${localStorage.getItem('Ashram_id')}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setKiranaItem(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchKiranaItem();

    return () => {}; // No cleanup needed
  }, []);

  return { kiranaItem, error };
};

// Component for displaying table header with kirana item names
const TableHeader = ({ kiranaItems }) => {
  return (
    <tr>
      <th class="tb-col">Date</th>
      <th class="tb-col">Schedule</th>
      <th class="tb-col">No of Students</th>
      {kiranaItems.map((kirana, index) => (
        <th class="tb-col" style={{ textAlign: 'center' }} key={index}>
          {kirana.Item_Name}
          <br />
          <span style={{ fontSize: '12px' }}>{kirana.Per_Student_in_grams}g</span>
        </th>
      ))}
    </tr>
  );
};

const Khadyan_panji = () => {
  const { monthData, error: monthError } = useFetchMonthData();
  const { kiranaItem, error: kiranaError } = useFetchKiranaItem();
  const tableRef = useRef(null);

  if (monthError || kiranaError) {
    return <p>Error: {monthError || kiranaError}</p>;
  }

  const tableRows = [];
  const itemTotals = Array(kiranaItem.length).fill(0); // Initialize array to hold totals for each kirana item

  monthData.forEach((monthItem, monthIndex) => {
    // Group lunch and dinner for the same date
    const groupedRows = [];
    for (let i = 0; i < monthItem.total_day; i++) {
      const uniqueKey = `${monthIndex}${i + 1}`;
      groupedRows.push(
        <tr key={uniqueKey}>
          <td rowspan="2">{uniqueKey}/{monthItem.selected_month}/{monthItem.year}</td>
          <td>lunch</td>
          <td>{monthItem.no_of_students}</td>
          {kiranaItem.map((kirana, index) => {
            const total = kirana.Per_Student_in_grams * monthItem.no_of_students;
            itemTotals[index] += total; // Update total for this item
            return <td key={`${uniqueKey}_${index}`}>{total}</td>;
          })}
        </tr>,
        <tr key={`${uniqueKey}_dinner`}>
          <td>dinner</td>
          <td>{monthItem.no_of_students}</td>
          {kiranaItem.map((kirana, index) => {
            const total = kirana.Per_Student_in_grams * monthItem.no_of_students;
            return <td key={`${uniqueKey}_${index}_dinner`}>{total}</td>;
          })}
        </tr>
      );
    }
    tableRows.push(groupedRows);
  });

  const totalRow = (
    <tr>
      <th>Total</th>
      <td></td>
      <td></td>
      {itemTotals.map((total, index) => (
        <th key={index}>{(total / 1000)*2}kg</th>
      ))}
    </tr>
  );

  const handleDownloadPDF = async () => {
    const input = tableRef.current; // Get the table element
    const canvas = await html2canvas(input); // Convert the table to a canvas
    const imgData = canvas.toDataURL('image/png'); // Get the image data URL
    const pdf = new jsPDF(); // Create a new PDF instance
    const imgHeight = canvas.height * 208 / canvas.width; // Calculate the height to maintain aspect ratio
    pdf.addImage(imgData, 'PNG', 0, 0, 208, imgHeight); // Add the image to the PDF
    
    // Check if monthData is not empty
    if (monthData.length > 0) {
      // Access the first element of the monthData array to get the month and year
      const month = monthData[0].selected_month;
      const year = monthData[0].year;
      
      // Set the filename including the month and year
      const filename = `${month}_${year}_lunch-dinner.pdf`;
      
      pdf.save(filename); // Save the PDF with the filename
    } else {
      console.error('Month data is empty');
    }
  };
  
  
  
  // Inside the Khadyan_panji component
return (
  <div class="nk-app-root">
    <div className='nk-main'>
      <Sidebar />
      <div className='nk-wrap'>
        <Fixed_header />
        <div class="nk-content">
          <div class="container">
            <div class="nk-content-inner">
              <div class="nk-content-body">
                <div class="nk-block-head">
                  <div class="nk-block-head-between flex-wrap gap g-2">
                    <div class="nk-block-head-content">
                      <h2 class="nk-block-title">
                        Khadyan Panji
                      </h2>
                      <nav>
                        <ol class="breadcrumb breadcrumb-arrow mb-0">
                          <li class="breadcrumb-item">
                            <a href="#">
                              Home
                            </a>
                          </li>
                          <li class="breadcrumb-item">
                            <a href="#">
                              Khadyan_panji
                            </a>
                          </li>
                        </ol>
                      </nav>
                    </div>
                    <div class="nk-block-head-content">
                     
                    </div>
                  </div>
                </div>
                <div class="nk-block">
                  <div class="card">
                    <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                    
                      <div class="dataTable-to" style={{ padding: '10px',display:'flex', flexDirection:'row-reverse' }}>
                      <button onClick={handleDownloadPDF}>Download as PDF</button> {/* Add the download button here */} 
                        <div className='Khadyan_panji_list' style={{display:'flex'}}>
                          <Link style={{ margin: '4px' }} className='badge text-bg-success-soft'>Janvery 2024</Link>
                          <Link style={{ margin: '4px' }} className='badge text-bg-success-soft'>Janvery 2024</Link>
                        </div>
                      </div>
                      <div class="dataTable-container table-responsive">
                        <table ref={tableRef} class="datatable-init table dataTable-table" data-nk-container="table-responsive">
                          <thead class="table-light">
                            <TableHeader kiranaItems={kiranaItem} />
                          </thead>
                          <tbody>
                            {tableRows}
                            {totalRow}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <Breakfast />
                </div>
                
              </div>
             
            </div>
            <Kirana_calculation/>
          </div>
          
        </div>
      </div>
    </div>
  </div>
);

};

export default Khadyan_panji;
