import React, { useState } from 'react';

const Ai = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const generateAnswer = (question) => {
    // Simple logic to generate an answer based on the question
    const answers = {
        'hello': 'hello I am a chatbot created by OpenAI.',
      'What is your name?': 'I am a chatbot created by OpenAI.',
      'How are you?': 'I am doing well, thank you!',
      'What time is it?': `I'm sorry, I can't provide real-time information.`,
      'Who created you?': 'I was created by a team of engineers at OpenAI.',
      'Can you tell a joke?': `Sure! Why don't scientists trust atoms? Because they make up everything!`,
      // Add more question-answer pairs as needed
    };

    return answers[question] || "I'm sorry, I don't understand that question.";
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const generatedAnswer = generateAnswer(question);
    setAnswer(generatedAnswer);
  };

  return (
    <div>
      <h2>Simple Question-Answer Component</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Enter your question:
          <input type="text" value={question} onChange={handleQuestionChange} />
        </label>
        <button type="submit">Ask</button>
      </form>
      {answer && (
        <div>
          <h3>Answer:</h3>
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

export default Ai;
