import React, { useState, useEffect } from 'react';

const Kirana_calculation = () => {
  const [items, setItems] = useState([]);
  const [noOfStudents, setNoOfStudents] = useState(0);
  const [monthData, setMonthData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await fetch(`https://api.rox-tech.com/ashram/kirana_calculation.php?Ashram_id=${localStorage.getItem('Ashram_id')}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setItems(data || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchMonthData = async () => {
      try {
        const response = await fetch(`https://api.rox-tech.com/ashram/month.php?Ashram_id=${localStorage.getItem('Ashram_id')}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setMonthData(data);
        setNoOfStudents(data.length > 0 ? data[0].no_of_students : 0);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchItems();
    fetchMonthData();
  }, []);

  const handleRateChange = (index, event) => {
    const { value } = event.target;
    setItems(prevItems =>
      prevItems.map((item, i) => (i === index ? { ...item, Rate: parseFloat(value) || 0 } : item))
    );
  };

  // Calculate total price
  const totalPrice = items.reduce((total, item) => {
    const itemPrice = (noOfStudents * item.Per_Student_in_grams * monthData[0]?.total_day / 1000 * (item.Rate || 0));
    return total + itemPrice;
  }, 0);

  return (
    <div className='card mt-5'>
      <table className='datatable-init table dataTable-table'>
        <thead className='table-light'>
          <tr>
            <th>Item Name</th>
            <th>Quantity</th>
            <th>Rate</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>{item.Item_Name}</td>
              <td>{noOfStudents * item.Per_Student_in_grams * monthData[0]?.total_day / 1000}kg</td>

              <td>
                <input
                  type="number"
                  value={item.Rate}
                  onChange={(e) => handleRateChange(index, e)}
                  step="0.01"
                  min="0"
                />
              </td>
              <td>{(noOfStudents * item.Per_Student_in_grams * monthData[0]?.total_day / 1000 * (item.Rate || 0)).toFixed(2)}</td>
            </tr>
          ))}
          <tr>
            <td colSpan="3" style={{ textAlign: 'right' }}>Total Price:</td>
            <td>{totalPrice.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Kirana_calculation;
