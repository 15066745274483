import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../components/side_bar';


const useFetchMonthData = () => {
  const [monthData, setMonthData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMonthData = async () => {
      try {
        const response = await fetch(`https://api.rox-tech.com/ashram/month.php?Ashram_id=${localStorage.getItem('Ashram_id')}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setMonthData(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchMonthData();

    return () => {}; // No cleanup needed
  }, []);

  return { monthData, error };
};

const useFetchKiranaItem = () => {
  const [kiranaData, setKiranaData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchKiranaData = async () => {
      try {
        const response = await fetch(`https://api.rox-tech.com/ashram/fetch_kirana_new.php?Ashram_id=${localStorage.getItem('Ashram_id')}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setKiranaData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
        setError(error.message);
      }
    };

    fetchKiranaData();
  }, []);

  return { kiranaData, error };
};

const TableHeader = ({ kiranaItems }) => {
  return (
    <tr>
      <th className="tb-col">Date</th>
    
      <th className="tb-col">No of Students</th>
      {kiranaItems.map((kirana, index) => (
        <React.Fragment key={index}>
          <th className="tb-col">Pooha<br></br><span style={{fontSize:'10px'}}>{kirana.pooha_weight_p_student}g/st</span></th>
          <th className="tb-col">muggfali <br></br><span style={{fontSize:'10px'}}>{kirana.muggfali_weight_p_student}g/st</span></th>
          <th className="tb-col">Chana<br></br><span style={{fontSize:'10px'}}>{kirana.chana_weight_p_student}g/st</span></th>
          
          <th className="tb-col">suji<br></br><span style={{fontSize:'10px'}}>{kirana.suji_weight_p_student}g/st</span></th>
          <th className="tb-col">dalda<br></br><span style={{fontSize:'10px'}}>{kirana.dalda_weight_p_student}g/st</span></th>
          <th className="tb-col">Daliya<br></br><span style={{fontSize:'10px'}}>{kirana.daliya_weight_p_student}g/st</span></th>
          <th className="tb-col">Shakkar<br></br><span style={{fontSize:'10px'}}>{kirana.nasta_shakkar_weight_p_student}g/st {kirana.nasta_shakkar_suji_weight_p_student}g/st</span></th>
          <th className="tb-col">chaypatti<br></br><span style={{fontSize:'10px'}}>{kirana.chaypatti_weight_p_student}g/st</span></th>
          <th className="tb-col">nasta_namak<br></br><span style={{fontSize:'10px'}}>{kirana.nasta_namak_weight_p_student}g/st</span></th>
          <th className="tb-col">nasta_haldi<br></br><span style={{fontSize:'10px'}}>{kirana.nasta_haldi_weight_p_student}g/st</span></th>
          <th className="tb-col">nasta_mirchi<br></br><span style={{fontSize:'10px'}}>{kirana.nasta_mirchi_weight_p_student}g/st</span></th>
          <th className="tb-col">nasta_dhaniya<br></br><span style={{fontSize:'10px'}}>{kirana.nasta_dhaniya_weight_p_student}g/st</span></th>
          <th className="tb-col">nasta_tel<br></br><span style={{fontSize:'10px'}}>{kirana.nasta_tel_weight_p_student}g/st</span></th>
          <th className="tb-col">Lakdi<br></br><span style={{fontSize:'10px'}}></span></th>
          <th className="tb-col">Jeera<br></br><span style={{fontSize:'10px'}}>{kirana.nasta_jeera_weight_p_student}g/st</span></th>
          <th className="tb-col">nasta pyaj<br></br><span style={{fontSize:'10px'}}>{kirana.nasta_pyaj_weight_p_student}g/st</span></th>
        </React.Fragment>
      ))}
    </tr>
  );
};

const Breakfast_table = () => {

  // ========================================================================================tableheading
  const { monthData, error: monthError } = useFetchMonthData();
  const { kiranaData, error: kiranaError } = useFetchKiranaItem();
  const tableRef = useRef(null);

  if (monthError || kiranaError) {
    return <p>Error: {monthError || kiranaError}</p>;
  }

  const tableRows = [];
  const itemTotals = Array(kiranaData.length).fill(0); // Initialize array to hold totals for each kirana item

  monthData.forEach((monthItem, monthIndex) => {
    // Group lunch and dinner for the same date
    const groupedRows = [];
    for (let i = 0; i < monthItem.total_day; i++) {
      const uniqueKey = `${monthIndex}${i + 1}`;
      groupedRows.push(
        <tr key={uniqueKey}>
          <td>{uniqueKey}/{monthItem.selected_month}/{monthItem.year}</td>
       
          <td>{monthItem.no_of_students}</td>
          {/* Render kirana items separachaypattiy */}
          {kiranaData.map((kirana, index) => (
              <td className="tb-col" key={index}>
                {(i === 0 || i === 4 || i === 8 || i === 12 || i === 16 || i === 20 || i === 24 || i === 28 || i === 32) && kirana.pooha_weight_p_student * monthItem.no_of_students}
              </td>
            ))}

          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
            {(i === 0 || i === 4 || i === 8 || i === 12 || i === 16 || i === 20 || i === 24 || i === 28 || i === 32) && kirana.muggfali_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
            {(i === 1 || i === 5 || i === 9 || i === 13  || i === 17 || i === 21 || i === 25 || i === 29 || i === 33 ) && kirana.chana_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
            {(i === 2 || i === 6 || i === 10 || i === 14 || i === 18 || i === 22 || i === 26 || i === 30 || i === 34 ) && kirana.suji_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
            {(i === 2 || i === 6 || i === 10 || i === 14 || i === 18 || i === 22 || i === 26 || i === 30 || i === 34) && kirana.dalda_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
            {(i === 3 || i === 7 || i === 11 || i === 15 || i === 19 || i === 23 || i === 27 || i === 31 || i === 35) && kirana.daliya_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          
         
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
            {(i === 0 || i === 1 || i === 3  || i === 4 || i === 5 || i === 7 || i === 8 || i === 9 ||  i === 11 || i === 12 
            || i === 13  || i === 15 || i === 16 || i === 17 || i === 19 ||  i === 20 ||i === 21  || i === 23 || i === 24 ||  i === 25 || i === 27  || i === 28  ||  i === 29  || i === 31) && kirana.nasta_shakkar_weight_p_student * monthItem.no_of_students}
            {(i === 2 || i === 6 || i === 10 || i === 14 || i === 18 || i === 22 || i === 26 || i === 30 || i === 34) && kirana.nasta_shakkar_suji_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.chaypatti_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
            {(i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29) &&  kirana.nasta_namak_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
            {(i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29) &&  kirana.nasta_haldi_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
            {(i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29) &&  kirana.nasta_mirchi_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
            {(i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29) &&  kirana.nasta_dhaniya_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
            {(i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29) &&  kirana.nasta_tel_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
            {  kirana.nasta_lakdi_weight}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
            {(i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29) &&  kirana.nasta_jeera_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
            {(i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29) &&  kirana.nasta_pyaj_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
        </tr>,
        
        
      );
    }
    tableRows.push(groupedRows);
  });

  const totalRow = (
    <tbody>
      {/* Render total row for each day */}
      {monthData.map((monthItem, index) => {
        // Calculate the totals once for all kirana items
        const totalValues = kiranaData.map((kirana) => {
          let poohaTotal = 0;
          let muggfaliTotal = 0;
          let chanaTotal = 0;
         
          let sujiTotal = 0;
          let daldaTotal = 0;
          let daliyaTotal = 0;
          let nasta_shakkar_weight_p_studentTotal = 0;
          let nasta_shakkar_suji_weight_p_studentnTotal = 0;
          let chaypattiTotal = 0;
          let namakTotal = 0;
          let nasta_haldiTotal = 0;
          let nasta_mirchiTotal = 0;
          let nasta_dhaniyaTotal = 0;
          let nasta_telTotal = 0;
          let nasta_lakdiTotal = 0;
          let nasta_jeeraTotal = 0;
          let nasta_pyaj_weight_p_studentTotal = 0;
          for (let i = 0; i < monthItem.total_day; i++) {
            if (i === 0 || i === 4 || i === 8 || i === 12 || i === 16 || i === 20 || i === 24 || i === 28 || i === 32) {
              poohaTotal += kirana.pooha_weight_p_student * monthItem.no_of_students;
            }
            if (i === 0 || i === 4 || i === 8 || i === 12 || i === 16 || i === 20 || i === 24 || i === 28 || i === 32) {
              muggfaliTotal += kirana.muggfali_weight_p_student * monthItem.no_of_students;
            }
            if (i === 1 || i === 5 || i === 9 || i === 13  || i === 17 || i === 21 || i === 25 || i === 29 || i === 33) {
              chanaTotal += kirana.chana_weight_p_student * monthItem.no_of_students;
            }
            
            if (i === 2 || i === 6 || i === 10 || i === 14 || i === 18 || i === 22 || i === 26 || i === 30 || i === 34) {
              sujiTotal += kirana.suji_weight_p_student * monthItem.no_of_students;
            }
            if (i === 2 || i === 6 || i === 10 || i === 14 || i === 18 || i === 22 || i === 26 || i === 30 || i === 34) {
              daldaTotal += kirana.dalda_weight_p_student * monthItem.no_of_students;
            }
            if (i === 3 || i === 7 || i === 11 || i === 15 || i === 19 || i === 23 || i === 27 || i === 31 || i === 35) {
              daliyaTotal += kirana.daliya_weight_p_student * monthItem.no_of_students;
            }
            if (i === 0 || i === 1 || i === 3  || i === 4 || i === 5 || i === 7 || i === 8 || i === 9 ||  i === 11 || i === 12 
            || i === 13  || i === 15 || i === 16 || i === 17 || i === 19 ||  i === 20 ||i === 21  || i === 23 || i === 24 ||  i === 25 || i === 27  || i === 28  ||  i === 29 || i === 31) {
              nasta_shakkar_weight_p_studentTotal += kirana.nasta_shakkar_weight_p_student * monthItem.no_of_students;
            }
            if (i === 2 || i === 6 || i === 10 || i === 14 || i === 18 || i === 22 || i === 26 || i === 30 || i === 34) {
              nasta_shakkar_suji_weight_p_studentnTotal += kirana.nasta_shakkar_suji_weight_p_student * monthItem.no_of_students;
            }
            if (i === 0 || i === 1 || i === 2  || i === 3 || i === 4 ||i === 5 ||i === 6 ||i === 7 ||i === 8 ||i === 9 ||i === 10 ||i === 11 ||i === 12 ||i === 13
              ||i === 14 ||i === 15 ||i === 16 ||i === 17 ||i === 18 ||i === 19 ||i === 20 ||i === 21 ||i === 22 ||i === 23 ||i === 24 ||i === 25 ||i === 26 ||i === 27
              ||i === 28 ||i === 29 ||i === 30 ||i === 31 ||i === 32 ) {
              chaypattiTotal += kirana.chaypatti_weight_p_student * monthItem.no_of_students;
            }
            if (i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29 ) {
              namakTotal += kirana.nasta_namak_weight_p_student * monthItem.no_of_students;
            }
            if (i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29) {
                nasta_haldiTotal += kirana.nasta_haldi_weight_p_student * monthItem.no_of_students;
            }
            if (i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29) {
                nasta_mirchiTotal += kirana.nasta_mirchi_weight_p_student * monthItem.no_of_students;
            }
            if (i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29) {
                nasta_dhaniyaTotal += kirana.nasta_dhaniya_weight_p_student * monthItem.no_of_students;
            }
            if (i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29) {
                nasta_telTotal += kirana.nasta_tel_weight_p_student * monthItem.no_of_students;
            }
            if (i === 0 || i === 1 || i === 2  || i === 3 || i === 4 ||i === 5 ||i === 6 ||i === 7 ||i === 8 ||i === 9 ||i === 10 ||i === 11 ||i === 12 ||i === 13
              ||i === 14 ||i === 15 ||i === 16 ||i === 17 ||i === 18 ||i === 19 ||i === 20 ||i === 21 ||i === 22 ||i === 23 ||i === 24 ||i === 25 ||i === 26 ||i === 27
              ||i === 28 ||i === 29 ||i === 30 ||i === 31 ||i === 32) {
                nasta_lakdiTotal += kirana.nasta_lakdi_weight * 1;
            }
            if (i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29) {
                nasta_jeeraTotal += kirana.nasta_jeera_weight_p_student * monthItem.no_of_students;
            }
            if (i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29) {
                nasta_pyaj_weight_p_studentTotal += kirana.nasta_pyaj_weight_p_student * monthItem.no_of_students;
            }
          }
          return { poohaTotal, muggfaliTotal, chanaTotal, sujiTotal, daldaTotal, daliyaTotal,
           nasta_shakkar_weight_p_studentTotal, nasta_shakkar_suji_weight_p_studentnTotal, chaypattiTotal,
            chaypattiTotal, namakTotal, nasta_haldiTotal, nasta_mirchiTotal, nasta_dhaniyaTotal, nasta_telTotal, nasta_lakdiTotal, nasta_jeeraTotal , nasta_pyaj_weight_p_studentTotal };
        });
  
        // Render the totals for all kirana items in a single row
        return (
          <tr key={index}>
            <th>Total</th> 
            <td colSpan={kiranaData.length}></td>
            {totalValues.map((totals, kiranaIndex) => (
              <React.Fragment key={kiranaIndex}>
                <td className="tb-col">{totals.poohaTotal}</td>
                <td className="tb-col">{totals.muggfaliTotal}</td>
                <td className="tb-col">{totals.chanaTotal}</td>
                
                <td className="tb-col">{totals.sujiTotal}</td>
                <td className="tb-col">{totals.daldaTotal}</td>
                <td className="tb-col">{totals.daliyaTotal}</td>
                <td className="tb-col">{totals.nasta_shakkar_weight_p_studentTotal + totals.nasta_shakkar_suji_weight_p_studentnTotal}</td>
                <td className="tb-col">{totals.chaypattiTotal}</td>
                <td className="tb-col">{totals.namakTotal}</td>
                <td className="tb-col">{totals.nasta_haldiTotal}</td>
                <td className="tb-col">{totals.nasta_mirchiTotal}</td>
                <td className="tb-col">{totals.nasta_dhaniyaTotal}</td>
                <td className="tb-col">{totals.nasta_telTotal}</td>
                <td className="tb-col">{totals.nasta_lakdiTotal}</td>
                <td className="tb-col">{totals.nasta_jeeraTotal}</td>
                <td className="tb-col">{totals.nasta_pyaj_weight_p_studentTotal}</td>
              </React.Fragment>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
  
  
  


  



  
  
  
  
  

  return (
  
          
           
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head">
                    <div className="nk-block-head-between flex-wrap gap g-2">
                      
                      
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="card">
                    <h5 className='mt-3' style={{marginLeft: '20px'}}>Breakfast</h5>

                      <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div className="dataTable-container table-responsive">
                          <table className="datatable-init table dataTable-table" data-nk-container="table-responsive">
                            <thead className="table-light">
                              <TableHeader kiranaItems={kiranaData} />
                            </thead>
                            <tbody>{tableRows}</tbody>
                            {totalRow}
                          </table>
                        </div>
                        <div className="dataTable-bottom"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        
         

  );
};

export default Breakfast_table;
