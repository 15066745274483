import React, { useState } from 'react';

const Register = () => {
  const [response, setResponse] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const formData = new FormData(event.target);
  
    try {
      const response = await fetch('https://api.rox-tech.com/ashram/registration.php', {
        method: 'POST',
        body: formData
      });
  
      // Check if the response is empty
      if (response.ok && response.status !== 204) {
        const data = await response.json();
  
        if (data.success) {
          localStorage.setItem('Ashram_id', formData.get('Ashram_id'));
          window.location.href = '/add_kiranaItem'; // Redirect to login page if registration is successful
      }
       else if (data.taken) {
          alert('Ashram ID already taken'); // Show an alert if Ashram ID is already taken
      } else {
          alert('Registration failed'); // Show an alert if registration fails for any other reason
      }
      
      } else {
        // Handle empty response or non-JSON response
        alert('Empty or non-JSON response received');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  return (
    <div className="card card col-lg-6 col-md-8" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '3%' }}>
      <h2 style={{ textAlign: 'center', marginTop: '30px' }}>Register</h2>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="row g-3">
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="schoolName" className="form-label">School Name:</label>
                <input className="form-control" type="text" name="schoolName" />
              </div>
              <div className="form-group mt-4">
                <label htmlFor="adhyakshikaName" className="form-label">Adhyakshika Name:</label>
                <input className="form-control" type="text" name="Adhyakshika_Name" />
              </div>
              <div className="form-group mt-4">
                <label htmlFor="Ashram_id" className="form-label">Ashram ID:</label>
                <input className="form-control" type="text" name="Ashram_id" />
              </div>
              <div className="form-groupmt-4">
                <label htmlFor="State" className="form-label">State:</label>
                <input className="form-control" type="text" name="State" />
              </div>
              <div className="form-group">
                <label htmlFor="District" className="form-label">District:</label>
                <input className="form-control" type="text" name="District" />
              </div>
              <div className="form-group">
                <label htmlFor="Village" className="form-label">Village:</label>
                <input className="form-control" type="text" name="Village" />
              </div>
              <div className="form-group">
                <label htmlFor="Pin_No" className="form-label">Pin No:</label>
                <input className="form-control" type="text" name="Pin_No" />
              </div>
              <div className="form-group">
                <label htmlFor="Phone_no" className="form-label">Phone no:</label>
                <input className="form-control" type="text" name="Phone_no" />
              </div>
              <div className="form-group">
                <label htmlFor="Email" className="form-label">Email:</label>
                <input className="form-control" type="text" name="Email" />
              </div>
              <div className="form-group">
                <label htmlFor="Password" className="form-label">Password:</label>
                <input className="form-control" type="text" name="Password" />
              </div>
              <div className="form-group">
                <label htmlFor="Address" className="form-label">Address:</label>
                <input className="form-control" type="text" name="Address" />
              </div>
            </div>
            <div className="form-group">
                <label htmlFor="About_Ashram" className="form-label">About Ashram:</label>
                <input className="form-control" type="text" name="About_Ashram" />
              </div>
            
          </div>
          
          <button type="submit" className="btn btn-primary mt-3">Submit</button>
        </form>
        {response && <p>{response}</p>}
      </div>
    </div>
  );
};

export default Register;
