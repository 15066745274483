import React from "react";
import Sidebar from "../components/side_bar";
import Fixed_header from "../components/fixed_header";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";
const Conferm_order = () => {
  return (
    <div class="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          {/* ==================================================================== */}
          <Fixed_header />

          {/* =================================================================================== */}
          <div class="nk-content">
            <div className="container">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                <div className="col-12">
                    <div className="card">
                    <div className="card-body">
                    <div className="row">
                    <div className="order-confirm-lft col-6">
                    <h1 >Thank you for your purchase! </h1>
                    <p>Your order will be processed within 24 hours during working days. We will call you very soon</p>
                    <h3>Billing Address</h3>
                    <table>
                      <tr>
                        <th>Name- </th>
                        <td>Roshan maravi</td>
                      </tr>
                      <tr>
                      <th>Address-</th>
                      <td>village + post duhaniya  dist dindori</td>
                      </tr>
                      <tr>
                        <th>Phone no-</th>
                        <td>7974840811</td>
                      </tr>
                      
                    </table>
                    </div>
                    <div className="order-confirm-right col-6">
                      <div className="order-recept">
                        <span></span>
                        <h2>Order Summary</h2>
                        <div className="order-date-time">
                          <div className="row">
                            <div className="order-date col-4">
                              <p>Date</p>
                              <h5>02 May 2023</h5>
                            </div>
                            <div className="order-date col-4">
                              <p>Order Number</p>
                              <h5>ghar45872</h5>
                            </div>
                            <div className="order-date col-4">
                              <p>Payment Method</p>
                              <h5>upi</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>
                        
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conferm_order;
