import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Login = () => {
    const [Ashram_id, setAshram_id] = useState('');
    const [Password, setPassword] = useState('');
    const [response, setResponse] = useState('');

    useEffect(() => {
        const storedAshramId = localStorage.getItem('Ashram_id');
        if (storedAshramId) {
            // Redirect the user to the desired page if already logged in
            window.location.href = '/'; // Change '/dashboard' to your desired URL
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://api.rox-tech.com/ashram/login.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({ Ashram_id, Password })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.text();
            setResponse(responseData);

            if (responseData === 'Loginnull') {
                localStorage.setItem('Ashram_id', Ashram_id);
                // Redirect to another page
                window.location.href = '/'; // Change '/dashboard' to your desired URL
            }
        } catch (error) {
            console.error('Error sending data to PHP API:', error);
        }
    };

    return (
        <div className="card col-lg-6 col-md-8" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '8%' }}>
            <h2 style={{ textAlign: 'center', marginTop: '30px' }}>Login</h2>
            <div className="card-body">
                <div className="bio-block">
                    <form onSubmit={handleSubmit}>
                        <div className="row g-3">
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label htmlFor="Ashram_id" className="form-label">Ashram ID:</label>
                                    <div className="form-control-wrap">
                                        <input className="form-control" type="text" id="Ashram_id" value={Ashram_id} onChange={(e) => setAshram_id(e.target.value)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Password" className="form-label">Password:</label>
                                    <div className="form-control-wrap">
                                        <input className="form-control" autoComplete='off' type="password" id="Password" value={Password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <button className='btn btn-primary mt-3' type="submit">Submit</button><br></br>
                                <Link className='mt-10'  to="/register">register</Link>
                            </div>
                        </div>
                    </form>
                    {response && <p>{response}</p>}
                </div>
            </div>
        </div>
    );
};

export default Login;
