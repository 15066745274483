import React, { useState, useEffect } from 'react';
import Sidebar from '../components/side_bar';
import Fixed_header from '../components/fixed_header';
import { IoIosArrowBack, IoIosArrowForward, IoTrashBin } from "react-icons/io";

const Cart = () => {
    const [cartData, setCartData] = useState([]);
    const [grandTotal, setGrandTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');

    useEffect(() => {
        fetchCartData();
    }, []);

    const fetchCartData = () => {
        setLoading(true);
        setError(null);

        fetch(`https://api.rox-tech.com/super_admin/get_cart.php?Ashram_id=${localStorage.getItem('Ashram_id')}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error fetching data');
                }
                return response.json();
            })
            .then(data => {
                setCartData(data.data);
                const total = data.data.reduce((acc, item) => acc + parseFloat(item.Selling_Price) * parseInt(item.Quantity), 0);
                setGrandTotal(total);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    };

    const handleDecrement = (id) => {
        const updatedCart = cartData.map(item => {
            if (item.id === id) {
                const newQuantity = Math.max(1, item.Quantity - 1); // Ensure quantity doesn't go below 1
                return { ...item, Quantity: newQuantity };
            }
            return item;
        });
        setCartData(updatedCart);
        updateGrandTotal(updatedCart);
    };
    
    const handleIncrement = (id) => {
        const updatedCart = cartData.map(item => {
            if (item.id === id) {
                const newQuantity = Math.max(1, parseInt(item.Quantity) + 1); // Parse quantity to integer before incrementing
                return { ...item, Quantity: newQuantity };
            }
            return item;
        });
        setCartData(updatedCart);
        updateGrandTotal(updatedCart);
    };
    
    

    const handleDelete = (id) => {
        fetch(`https://api.rox-tech.com/super_admin/delete_cart.php?item_id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                // Add any necessary headers here
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error deleting item');
            }
            // Remove the deleted item from the cart
            const updatedCart = cartData.filter(item => item.id !== id);
            setCartData(updatedCart);
            updateGrandTotal(updatedCart);
        })
        .catch(error => {
            setError(error.message);
        });
    };

    const updateGrandTotal = (updatedCart) => {
        const total = updatedCart.reduce((acc, item) => acc + parseFloat(item.Selling_Price) * parseInt(item.Quantity), 0);
        setGrandTotal(total);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            const formData = new FormData(event.target);
            const response = await fetch('https://api.rox-tech.com/super_admin/order.php', {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to save cart data');
            }
    
            // Parse the response body as JSON
            const responseData = await response.json();
            setResponseMessage(responseData.message);
            // Log the response data
            console.log('Response:', responseData);
            window.location.href = '/';
    
            // Handle success, e.g., show a success message
        } catch (error) {
            console.error('Error saving cart data:', error);
            window.location.href = 'conferm_order';
        }
    };
    
    

    return (
        <div className='nk-app-root'>
            <div className='nk-main'>
                <Sidebar />
                <div className='nk-wrap'>
                    <Fixed_header />

                    <div className="nk-content">
                        <div className="container">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="nk-block-head">
                                        {/* Your block head content */}
                                    </div>
                                    <div className="nk-block">
                                        <div className="card">
                                            <div className="nk-invoice">
                                                <div className="nk-invoice-body">
                                                    {/* {loading && <p>Loading...</p>}
                                                    {error && <p>Error: {error}</p>} */}
                                                    {!loading && !error && (
    <div className="table-responsive">
        <form onSubmit={handleSubmit}>
            <table className="table nk-invoice-table">
                <thead className="table-light">
                    <tr>
                        <th className="tb-col" style={{ paddingLeft: '30px' }}>
                            <span className="overline-title">
                                Vegetable Name
                            </span>
                        </th>
                        <th className="tb-col">
                            <span className="overline-title">
                                Price
                            </span>
                        </th>
                        <th className="tb-col">
                            <span className="overline-title">
                                Quantity
                            </span>
                        </th>
                        <th className="tb-col tb-col-end">
                            <span className="overline-title">
                                Total
                            </span>
                        </th>
                        <th className="tb-col tb-col-end">
                            <span className="overline-title">
                                Actions
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {cartData.map(vegetable => (
                        <tr key={vegetable.id}>
                            <td className="tb-col" style={{ paddingLeft: '0px' }}>
                                <img className='cart-img' src={vegetable.Vegitable_image} alt={vegetable.Ashram_name} />
                                <span>
                                    {vegetable.Vegitable_name}
                                </span>
                            </td>
                            <td className="tb-col">
                                <span>
                                    ₹{vegetable.Selling_Price}
                                </span>
                            </td>
                            <td className="tb-col">
                                <span>
                                    <button type="button" onClick={() => handleDecrement(vegetable.id)}><IoIosArrowBack /></button>
                                    <input name='quantity[]' value={vegetable.Quantity} readOnly />
                                    <button type="button" onClick={() => handleIncrement(vegetable.id)}><IoIosArrowForward /></button>
                                </span>
                            </td>
                            <td className="tb-col tb-col-end">
                                <span>
                                    ₹{parseFloat(vegetable.Selling_Price) * parseInt(vegetable.Quantity)}
                                </span>
                            </td>
                            <td className="tb-col tb-col-end">
                                <button type="button" onClick={() => handleDelete(vegetable.id)} style={{ border: 'none', background: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Delete</button>
                            </td>
                            <input type='hidden' name='Vegitable_name[]' value={vegetable.Vegitable_name} />
                            <input type='hidden' name='Quantity[]' value={vegetable.Quantity} />
                            <input type='hidden' name='Adhyakshika_Name[]' value={vegetable.Adhyakshika_Name} />
                            <input type="hidden" name='Address[]' value={vegetable.Address} />
                            <input type='hidden' name='Phone_no[]' value={vegetable.Phone_no}/>
                            <input type='hidden' name='Ashram_name[]' value={vegetable.Ashram_name}></input>
                            <input type='hidden' name='Selling_Price[]' value={vegetable.Selling_Price}></input>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="4">Grand Total:</td>
                        <td className="tb-col-end">${grandTotal}</td>
                    </tr>
                </tfoot>
            </table>
            <button type="Submit">Submit</button>
            {responseMessage}
        </form>
    </div>
)}

                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cart;
