import React, { useState, useEffect } from 'react';

const ConnectionCheck = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <div>
      {!isOnline && (
        <div style={{
          fontSize: '77px',
          zIndex: '9999',
          position: 'fixed',
          background: '#ff6969',
          height: '100%',
          width: '100%',
          textAlign: 'center',
          paddingTop: '40vh' // Adjust vertical alignment
        }}>
          Please check your connection!
        </div>
      )}
    </div>
  );
};

export default ConnectionCheck;
