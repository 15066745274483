import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const Store = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null); // Store reference to the chart instance

  useEffect(() => {
    const data = {
      labels: ["Chawal", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      values: [65, 59, 20, 81, 56, 55, 40, 90, 85, 60, 75, 95]
    };

    const ctx = chartRef.current.getContext("2d");

    // Destroy previous chart instance if it exists
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Create new chart instance
    chartInstance.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: data.labels,
        datasets: [{
          label: 'Bar Chart',
          data: data.values,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

    // Cleanup function
    return () => {
      // Destroy chart instance on component unmount
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return (
    
      <canvas ref={chartRef} />
    
  );
};

export default Store;
