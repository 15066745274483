import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const useFetchMonthData = () => {
  const [monthData, setMonthData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMonthData = async () => {
      try {
        const response = await fetch(`https://api.rox-tech.com/ashram/month.php?Ashram_id=${localStorage.getItem('Ashram_id')}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setMonthData(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchMonthData();

    return () => {}; // No cleanup needed
  }, []);

  return { monthData, error };
};

// Custom hook to fetch breakfast item data
const useFetchBreakfastItem = () => {
  const [breakfastItem, setBreakfastItem] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBreakfastItem = async () => {
      try {
        const response = await fetch(`https://api.rox-tech.com/ashram/fatch_breakfast.php?Ashram_id=${localStorage.getItem('Ashram_id')}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setBreakfastItem(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchBreakfastItem();

    return () => {}; // No cleanup needed
  }, []);

  return { breakfastItem, error };
};

// Component for displaying table header with breakfast item names
const TableHeader = ({ breakfastItems }) => {
  return (
    <tr>
      <th className="tb-col">Date</th>
      <th className="tb-col">Schedule</th>
      <th className="tb-col">No of Students</th>
      {breakfastItems.map((item, index) => (
        <th className="tb-col" style={{ textAlign: 'center' }} key={index}>
          {item.Item_Name}
          <br />
          <span style={{ fontSize: '12px' }}>{item.Per_Student_in_grams}g</span>
        </th>
      ))}
    </tr>
  );
};

const Breakfast = () => {
  const { monthData, error: monthError } = useFetchMonthData();
  const { breakfastItem, error: breakfastError } = useFetchBreakfastItem();

  if (monthError || breakfastError) {
    return <p>Error: {monthError || breakfastError}</p>;
  }

  const tableRows = [];
  const itemTotals = Array(breakfastItem.length).fill(0); // Initialize array to hold totals for each breakfast item

  monthData.forEach((monthItem, monthIndex) => {
    const groupedRows = [];
    let skipDays = 0; // Track the number of days to skip
    
    for (let i = 0; i < monthItem.total_day; i++) {
      const uniqueKey = `${monthIndex}${i + 1}`;
      
     
      
      groupedRows.push(
        <tr key={uniqueKey}>
          <td>{uniqueKey}/{monthItem.selected_month}/{monthItem.year}</td>
          <td>Breakfast</td>
          <td>{monthItem.no_of_students}</td>
          {breakfastItem.map((item, index) => {
            const total = item.Per_Student_in_grams * monthItem.no_of_students;
            itemTotals[index] += total; // Update total for this item
            return (
              <td  key={`${uniqueKey}_${index}`}>
               {total} 
              </td>
            );
          })}
        </tr>
      );
    }
    tableRows.push(groupedRows);
  });

  const totalRow = (
    <tr>
      <th>Total</th>
      <td></td>
      <td></td>
      {itemTotals.map((total, index) => (
        <th key={index}>{total / 1000}kg</th>
      ))}
    </tr>
  );

  const handleDownloadPDF = async () => {
    const input = document.getElementById('breakfastTable'); // Get the table element
    const canvas = await html2canvas(input); // Convert the table to a canvas
    const imgData = canvas.toDataURL('image/png'); // Get the image data URL
    const pdf = new jsPDF(); // Create a new PDF instance
    const imgHeight = canvas.height * 208 / canvas.width; // Calculate the height to maintain aspect ratio
    pdf.addImage(imgData, 'PNG', 0, 0, 208, imgHeight); // Add the image to the PDF
    
    // Check if monthData is not empty
    if (monthData.length > 0) {
      // Access the first element of the monthData array to get the month and year
      const month = monthData[0].selected_month;
      const year = monthData[0].year;
      
      // Set the filename including the month and year
      const filename = `${month}_${year}_breakfast.pdf`;
      
      pdf.save(filename); // Save the PDF with the filename
    } else {
      console.error('Month data is empty');
    }
  };

  return (
    <div className="card mt-5">
      <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
        <h6 style={{ padding: '10px' }}>Breakfast</h6>
        <div style={{ padding: '10px', display: 'flex', flexDirection: 'row-reverse' }}>
          <button onClick={handleDownloadPDF}>Download as PDF</button>
        </div>
        <div className="dataTable-container table-responsive">
          <table id="breakfastTable" className="datatable-init table dataTable-table" data-nk-container="table-responsive">
            <thead className="table-light">
              <TableHeader breakfastItems={breakfastItem} />
            </thead>
            <tbody>
              {tableRows}
              {totalRow}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Breakfast;
