import React, { useState } from 'react'
import Sidebar from '../components/side_bar'
import Fixed_header from '../components/fixed_header'

const Add_Student = () => {
  const [response, setResponse] = useState('');

    

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const formData = new FormData(event.target);
        
        try {
            const response = await fetch('https://api.rox-tech.com/ashram/add_student.php', {
                method: 'POST',
                body: formData
            });
            
            // Check if the response is empty
            if (response.ok && response.status !== 204) {
                const data = await response.json();
                
                if (data.success) {
                    window.location.href = '/student_list'; // Redirect to login page if registration is successful
                }
                else {
                    alert(' failed'); // Show an alert if registration fails for any other reason
                }
                
            } else {
                // Handle empty response or non-JSON response
                alert('Empty or non-JSON response received');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
  return (
    <div class="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          {/* ==================================================================== */}
          <Fixed_header />

          {/* =================================================================================== */}
          <div class="nk-content">
          <div class="container">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head">
        <div class="nk-block-head-between flex-wrap gap gap-2">
          <div class="nk-block-head-content">
            <div class="d-flex flex-column flex-md-row align-items-md-center">
              <div class="media media-middle media-huge media-circle">
                <img src="https://react.nioboard.themenio.com/images/avatar/a.jpg" alt="user" class="img-thumbnail"/>
              </div>
              <div class="mt-3 mt-md-0 ms-md-3">
                <h3 class="title mb-1">
                  Florenza Desporte
                </h3>
                <span class="small">
                  Owner &amp; Founder
                </span>
                <ul class="nk-list-option pt-1">
                  <li>
                    <em class="icon ni ni-map-pin">
                    </em>
                    <span class="small">
                      California, United States
                    </span>
                  </li>
                  <li>
                    <em class="icon ni ni-building">
                    </em>
                    <span class="small">
                      Softnio
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="nk-block-head-content">
            <ul class="d-flex gap g-2">
              <li class="d-none d-md-block">
                <a class="btn btn-soft btn-primary" href="/user-manage/user-profile/uid01">
                  <em class="icon ni ni-user">
                  </em>
                  <span>
                    View Profile
                  </span>
                </a>
              </li>
              <li class="d-md-none">
                <a class="btn btn-soft btn-primary btn-icon" href="/user-manage/user-profile/uid01">
                  <em class="icon ni ni-user">
                  </em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="nk-block">
        <div class="card-gutter-md card">
          <div class="card-body">
            <div class="bio-block">
              <h4 class="bio-block-title mb-4">
                Acadmic Infomation
              </h4>
              <form onSubmit={handleSubmit} class="">
                <div class="g-3 row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label" for="fullname">
                        Full Name
                      </label>
                      <div class="form-control-wrap">
                        <input placeholder="Full name" type="text" id="student_name" name='student_name' class="form-control"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label" for="email">
                      Roll number
                      </label>
                      <div class="form-control-wrap">
                        <input placeholder="Roll Number" type="text" id="roll_number" name='roll_number' class="form-control"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label" for="company">
                      Class
                      </label>
                      <div class="form-control-wrap">
                      <select class="form-control" name='class' id="classSelect">
                        <option value="1">1st Class</option>
                        <option value="2">2nd Class</option>
                        <option value="3">3rd Class</option>
                        <option value="4">4th Class</option>
                        <option value="5">5th Class</option>
                      </select>
                      </div>
                    </div>
                  </div>
                  </div>
                  <h4 class="bio-block-title mb-4 pt-5">Basic Infomation</h4>
                  <div class="g-3 row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label" for="city">
                        Father Name
                      </label>
                      <div class="form-control-wrap">
                        <input placeholder="Father Name" type="text" id="father_name" name='father_name' class="form-control"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label" for="city">
                        Father's Phone Number
                      </label>
                      <div class="form-control-wrap">
                        <input placeholder="Father's Phone Number" type="number" id="father_number" name='father_number' class="form-control"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label" for="city">
                        Mother Name
                      </label>
                      <div class="form-control-wrap">
                        <input placeholder="Mother Name" type="text" id="mother_name" name='mother_name' class="form-control"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label" for="city">
                        Mother's Phone Number
                      </label>
                      <div class="form-control-wrap">
                        <input placeholder="Mother's Phone Number" type="number" id="mother_number" name='mother_number' class="form-control"/>
                      </div>
                    </div>
                  </div>





                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label" for="city">
                        village/City
                      </label>
                      <div class="form-control-wrap">
                        <input placeholder="City" type="text" id="village" name='village' class="form-control"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label" for="city">
                        Student photo
                      </label>
                      <div class="form-control-wrap">
                      <input placeholder="Student photo" type="file" id="photo" name="photo" class="form-control" accept="image/*" capture="camera"/>

                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="form-label" for="address">
                        Address
                      </label>
                      <div class="form-control-wrap">
                        <input placeholder="e.g. California, United States" type="text" id="address" name='address'
                        class="form-control"/>
                      </div>
                    </div>
                  </div>
                  
                  
                  <div class="col-lg-12">
                    <button type="submit" class="btn btn-primary">
                      Update Profile
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
            </div>
            </div>
            </div>
            
      
    </div>
  )
}

export default Add_Student;
