import React, { useState, useEffect } from 'react';
import ConnectionCheck from '../components/ConnectionCheck';
import Sidebar from '../components/side_bar';
import Fixed_header from '../components/fixed_header';
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { GiCheckMark } from "react-icons/gi";

const Vegitable_fruits = () => {
  const [vegetables, setVegetables] = useState([]);
  const [ashramName, setAshramName] = useState('');
  const [Adhyakshika_Name, setAdhyakshika_Name] = useState('');
  const [Address, setAddress] = useState('');
  const [Phone_no, setPhone_no] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    // Fetch vegetables data
    fetch('https://api.rox-tech.com/super_admin/fatch_vegitables.php')
      .then((response) => response.json())
      .then((data) => setVegetables(data))
      .catch((error) => console.error('Error fetching vegetables:', error));

    // Fetch ashram name
    const ashramId = localStorage.getItem('Ashram_id');
    fetch(`https://api.rox-tech.com/ashram/get_ashram_info.php?Ashram_id=${ashramId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setAshramName(data.schoolName);
        setAdhyakshika_Name(data.Adhyakshika_Name);
        setAddress(data.Address);
        setPhone_no(data.Phone_no);
      })
      .catch((error) => {
        console.error('Error fetching ashram name:', error);
      });
  }, []);

  const handleIncrement = (id) => {
    setVegetables((prevVegetables) =>
      prevVegetables.map((vegetable) =>
        vegetable.id === id ? { ...vegetable, quantity: (vegetable.quantity || 0) + 1 } : vegetable
      )
    );
  };

  const handleDecrement = (id) => {
    setVegetables((prevVegetables) =>
      prevVegetables.map((vegetable) =>
        vegetable.id === id && (vegetable.quantity || 0) > 0 ? { ...vegetable, quantity: vegetable.quantity - 1 } : vegetable
      )
    );
  };

  const getQuantity = (id) => {
    const vegetable = vegetables.find((veg) => veg.id === id);
    return vegetable ? (vegetable.quantity || 0) : 0;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const formData = new FormData(event.target);
  
    try {
      const response = await fetch('https://api.rox-tech.com/super_admin/add_to_cart.php', {
        method: 'POST',
        body: formData
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          setResponseMessage(data.message);
        } else {
          setResponseMessage(data.message); // Set the response message
        }
      } else {
        const errorData = await response.json();
        setResponseMessage(errorData.message); // Set the response message
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('Something went wrong adding cart');
    }
    setTimeout(() => {
      setResponseMessage('');
    }, 5000);
  };
  
  

  return (
    <div className="nk-app-root">
      <ConnectionCheck />
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          <Fixed_header />
          <div className="card card col-lg-12" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '3%' }}>
            <h2 style={{ textAlign: 'center', marginTop: '60px' }}>Vegetables and Fruits</h2>
            <div className="card-body">
            <div className={`cart-added-div ${responseMessage ? 'show' : ''}`}><GiCheckMark />{responseMessage }</div>

              <div className='row'>
                {vegetables.map((vegetable) => (
                  <div key={vegetable.id} className="col-lg-3 col-6 mb-4">
                    <div className="vegi-content">
                      <div className="vegi-content-inn">
                        <span>{vegetable.Selling_Price}% OFF</span>
                        <img className='vegimg' src={`https://api.rox-tech.com/super_admin/vegitables_img/${vegetable.Vegitable_image}`} alt={vegetable.name} />
                      </div>
                      <div className="vegi-prizes">
                        <h5>{vegetable.Vegitable_name}</h5>
                        <h6>₹{vegetable.Offer_price}&nbsp;<span className="overline-text">₹{vegetable.Selling_Price}</span></h6>
                        
                        <form onSubmit={handleSubmit}>
                          <div className='add_to_cart'>
                            <button type="button" onClick={() => handleDecrement(vegetable.id)}><IoIosArrowBack /></button>
                            <input
  type="text"
  name='Quantity'
  value={getQuantity(vegetable.id) > 0 ? getQuantity(vegetable.id) : 1}
  readOnly
  required={getQuantity(vegetable.id) > 0}
/>

                          <input type='hidden' name='Vegitable_id' value={vegetable.id}/>
                          <input type='hidden' name="Vegitable_name" value={vegetable.Vegitable_name}  />
                            <input type='hidden' name='Ashram_name' value={ashramName} />
                            <input type='hidden' name='Adhyakshika_Name' value={Adhyakshika_Name} />
                            <input type='hidden' name='Address' value={Address}/>
                            <input type='hidden' name='Phone_no' value={Phone_no}/> 
                            <input type='hidden' name='Vegitable_image'  value={`https://api.rox-tech.com/super_admin/vegitables_img/${vegetable.Vegitable_image}`}/>
                            <input type='hidden' value={localStorage.getItem('Ashram_id')} name='Ashram_id' />
                            <input type='hidden' value={vegetable.Selling_Price} name='Selling_Price'></input>
                            <button type="button" onClick={() => handleIncrement(vegetable.id)}><IoIosArrowForward /></button>
                          </div>
                          
                          
                          <button className='Add to cart' type='submit'>Add to cart</button>
                        </form>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vegitable_fruits;
