import React, { useState, useEffect } from 'react';
import Logout from './log_out';
import { FaPowerOff } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { AiOutlineShoppingCart } from "react-icons/ai";

const Fixed_header = () => {
    const [adhyakshikaName, setAdhyakshikaName] = useState('');
    const [error, setError] = useState(null);
    const [cartItemCount, setCartItemCount] = useState(0);

    // Function to fetch Adhyakshika name and cart item count
    const fetchData = () => {
        // Fetch Adhyakshika name
        fetch(`https://api.rox-tech.com/ashram/Adhyakshika_Name.php?Ashram_id=${localStorage.getItem('Ashram_id')}`)
            .then(response => response.json())
            .then(data => setAdhyakshikaName(data.AdhyakshikaName))
            .catch(error => setError(error.message));

        // Fetch count of items in the cart
        fetch(`https://api.rox-tech.com/super_admin/get_cart.php?Ashram_id=${localStorage.getItem('Ashram_id')}`)
            .then(response => response.json())
            .then(data => setCartItemCount(data.row_count))
            .catch(error => setError(error.message));
    };

    // Fetch data on component mount
    useEffect(() => {
        fetchData();

        
        const interval = setInterval(fetchData, 300);

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, []);
  
  return (
<div className='nk-header nk-header-fixed'>
  <div className='container-fluid'>
  <div className="nk-header-wrap">
  <div className="nk-header-logo ms-n1">
    
  <div className="nk-header-logo ms-n1">
                        {/* Display Ashram ID */}
                        Ashram ID: {localStorage.getItem('Ashram_id')}
                    </div>
                    
                    <div style={{marginLeft:'5px'}} className="adhyakshika-name">
                        Adhyakshika Name: {adhyakshikaName}
                    </div>
    
  </div>
  <nav className="nk-header-menu nk-navbar">
    <ul className="nk-nav">
      <li className="nk-nav-item has-sub">
        <a href="#" className="nk-nav-link nk-nav-toggle">
          <span className="nk-nav-text">
          
            Dashboard   
          </span>
        </a>
        <ul className="nk-nav-sub nk-nav-sub-lg" style={{ 
          position: 'absolute',
          inset: '0px auto auto 0px',
          margin: '0px',
          transform: 'translate3d(12px, 52px, 0px)'
        }}
        data-popper-placement="bottom-start">
          <li className="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/index.html" className="nk-nav-link bg-primary-soft-hover">
              <div className="media-group flex-grow-1">
                <div className="media media-md media-middle media-border text-bg-primary-soft-outline">
                  <em className="icon ni ni-dashboard-fill">
                  </em>
                </div>
                <div className="media-text flex-grow-1">
                  <span className="title">
                    Default
                  </span>
                  <span className="sub-text d-block">
                    Website Analytics
                  </span>
                </div>
              </div>
            </a>
          </li>
          <li className="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/index-ecommerce.html" className="nk-nav-link bg-secondary-soft-hover">
              <div className="media-group flex-grow-1">
                <div className="media media-md media-middle media-border text-bg-secondary-soft-outline">
                  <em className="icon ni ni-cart-fill">
                  </em>
                </div>
                <div className="media-text flex-grow-1">
                  <span className="title">
                    eCommerce
                  </span>
                  <span className="sub-text d-block">
                    Sales reports
                  </span>
                </div>
              </div>
            </a>
          </li>
          <li className="nk-nav-item">
            <a href="https://html.nioboard.themenio.com/index-project.html" className="nk-nav-link bg-success-soft-hover">
              <div className="media-group flex-grow-1">
                <div className="media media-md media-middle media-border text-bg-success-soft-outline">
                  <em className="icon ni ni-link-group">
                  </em>
                </div>
                <div className="media-text flex-grow-1">
                  <span className="title">
                    Project
                  </span>
                  <span className="sub-text d-block">
                    Tasts, graphs &amp; charts
                  </span>
                </div>
              </div>
            </a>
          </li>
          
         
        </ul>
      </li>
      
    </ul>
  </nav>
  <div className="nk-header-tools">
    <ul className="nk-quick-nav ms-2">
      <li className="dropdown">
        
       
        
      </li>
      <li>
        
      
&nbsp; 

 

      </li>
      <li className="dropdown">
      <Link to="/profile" >
          <div className="d-sm-none">
            <div className="media media-md media-circle">
              <img src="https://html.nioboard.themenio.com/images/avatar/a.jpg" alt=""
              className="img-thumbnail" />
            </div>
          </div>
          <div className="d-none d-sm-block">
            <div className="media media-circle">
              <img src="https://html.nioboard.themenio.com/images/avatar/a.jpg" alt=""
              className="img-thumbnail"/>
            </div>
          </div>
        </Link>
      </li>
      &nbsp; 
      <li>
      <Link className="btn btn-icon btn-md btn-zoom d-none d-sm-inline-flex"  to="/cart"
       >
       <em className="icon ni ni-signout cart-count"><AiOutlineShoppingCart /><span>{cartItemCount}</span></em>  
      </Link>
      </li>
      &nbsp;  
      <li>
      <li><Link to="/logout" className="btn btn-icon btn-md btn-zoom d-none d-sm-inline-flex" onclick="logout()">
         <em className="icon ni ni-signout"><FaPowerOff /></em> 
        </Link></li>
      
</li>
      
    </ul>
  </div>
</div>




  </div>
</div>

  )
}
export default Fixed_header;