import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
            <h2>Privacy Policy</h2>
            <p>
                This privacy policy outlines how we collect, use, and protect your personal information when you use our services. 
                We respect your privacy and are committed to protecting it.
            </p>
            <h3>Information Collection</h3>
            <p>
                We may collect personal information such as your name, email address, and phone number when you register or use our services.
            </p>
            <h3>Information Usage</h3>
            <p>
                We may use your personal information to provide and improve our services, communicate with you, and personalize your experience.
            </p>
            <h3>Information Sharing</h3>
            <p>
                We do not share your personal information with third parties except as required by law or with your consent.
            </p>
            <h3>Security</h3>
            <p>
                We implement security measures to protect your personal information from unauthorized access or disclosure.
            </p>
            <h3>Updates</h3>
            <p>
                We may update this privacy policy from time to time. Please review it periodically for changes.
            </p>
            <h3>Contact Us</h3>
            <p>
                If you have any questions about this privacy policy, please contact us.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
