import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../components/side_bar';
import Fixed_header from '../components/fixed_header';
import Make_month from '../components/make_month';
import Breakfast_table from './breakfastatble';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const useFetchMonthData = () => {
  const [monthData, setMonthData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMonthData = async () => {
      try {
        const response = await fetch(`https://api.rox-tech.com/ashram/month.php?Ashram_id=${localStorage.getItem('Ashram_id')}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setMonthData(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchMonthData();

    return () => {}; // No cleanup needed
  }, []);

  return { monthData, error };
};

const useFetchKiranaItem = () => {
  const [kiranaData, setKiranaData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchKiranaData = async () => {
      try {
        const response = await fetch(`https://api.rox-tech.com/ashram/fetch_kirana_new.php?Ashram_id=${localStorage.getItem('Ashram_id')}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setKiranaData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
        setError(error.message);
      }
    };

    fetchKiranaData();
  }, []);

  return { kiranaData, error };
};

const TableHeader = ({ kiranaItems }) => {
  return (
    <tr>
      <th className="tb-col">Date</th>
      <th className="tb-col">Schedule</th>
      <th className="tb-col">No of Students</th>
      {kiranaItems.map((kirana, index) => (
        <React.Fragment key={index}>
          <th className="tb-col">Chawal<br></br><span style={{fontSize:'10px'}}>{kirana.chawal_weight_p_student}g/st</span></th>
          <th className="tb-col">Aata <br></br><span style={{fontSize:'10px'}}>{kirana.aata_weight_p_student}g/st</span></th>
          <th className="tb-col">Dal<br></br><span style={{fontSize:'10px'}}>{kirana.dal_weight_p_student}g/st</span></th>
          <th className="tb-col">Sabji<br></br><span style={{fontSize:'10px'}}>{kirana.sabji_weight_p_student}g/st</span></th>
          <th className="tb-col">Namak<br></br><span style={{fontSize:'10px'}}>{kirana.namak_weight_p_student}g/st</span></th>
          <th className="tb-col">Lakdi<br></br><span style={{fontSize:'10px'}}></span></th>
          <th className="tb-col">Mirchi<br></br><span style={{fontSize:'10px'}}>{kirana.mirchi_weight_p_student}g/st</span></th>
          <th className="tb-col">Haldi<br></br><span style={{fontSize:'10px'}}>{kirana.haldi_weight_p_student}g/st</span></th>
          <th className="tb-col">Dhaniya<br></br><span style={{fontSize:'10px'}}>{kirana.dhaniya_weight_p_student}g/st</span></th>
          <th className="tb-col">Jeera<br></br><span style={{fontSize:'10px'}}>{kirana.Shakkar_weight_p_student}g/st</span></th>
          <th className="tb-col">Tel<br></br><span style={{fontSize:'10px'}}>{kirana.tel_weight_p_student}g/st</span></th>
          <th className="tb-col">Rai<br></br><span style={{fontSize:'10px'}}>{kirana.Rai_weight_p_student}g/st</span></th>
          <th className="tb-col">kadimirchi<br></br><span style={{fontSize:'10px'}}>{kirana.kadimirchi_weight_p_student}g/st</span></th>
        </React.Fragment>
      ))}
    </tr>
  );
};

const Khadyan_panji_new = () => {

  // ========================================================================================tableheading
  const { monthData, error: monthError } = useFetchMonthData();
  const { kiranaData, error: kiranaError } = useFetchKiranaItem();
  const tableRef = useRef(null);

  if (monthError || kiranaError) {
    return <p>Error: {monthError || kiranaError}</p>;
  }

  const tableRows = [];
  const itemTotals = Array(kiranaData.length).fill(0); // Initialize array to hold totals for each kirana item

  monthData.forEach((monthItem, monthIndex) => {
    // Group lunch and dinner for the same date
    const groupedRows = [];
    for (let i = 0; i < monthItem.total_day; i++) {
      const uniqueKey = `${monthIndex}${i + 1}`;
      groupedRows.push(
        <tr key={uniqueKey}>
          <td rowSpan="2">{uniqueKey}/{monthItem.selected_month}/{monthItem.year}</td>
          <td>lunch</td>
          <td>{monthItem.no_of_students}</td>
          {/* Render kirana items separately */}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.chawal_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.aata_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.dal_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.sabji_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.namak_weight_p_student * monthItem.no_of_students}
            </td>
          ))} 
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.lakdi_weight_p_student}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.mirchi_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.haldi_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.dhaniya_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.Shakkar_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.tel_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.Rai_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.kadimirchi_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
        </tr>,
        <tr key={`${uniqueKey}_dinner`}>
          <td>dinner</td>
          <td>{monthItem.no_of_students}</td>
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.chawal_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.aata_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.dal_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.sabji_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.namak_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.lakdi_weight_p_student}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.mirchi_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.haldi_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.dhaniya_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.Shakkar_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.tel_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.Rai_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
          {kiranaData.map((kirana, index) => (
            <td className="tb-col" key={index}>
              {kirana.kadimirchi_weight_p_student * monthItem.no_of_students}
            </td>
          ))}
        </tr>
        
      );
    }
    tableRows.push(groupedRows);
  });

  const totalRow = (
    <tbody>
    <tr>
      <th>Total</th>
      <td></td>
      <td></td>
      {kiranaData.map((kirana, index) => (
        <th className="tb-col" key={index}>
          {kirana.chawal_weight_p_student * 2 * monthData.reduce((acc, curr) => acc + curr.no_of_students, 0) * monthData.reduce((acc, curr) => acc + curr.total_day, 0)}
        </th>
      ))}
      {kiranaData.map((kirana, index) => (
        <th className="tb-col" key={index}>
          {kirana.aata_weight_p_student * 2 * monthData.reduce((acc, curr) => acc + curr.no_of_students, 0) * monthData.reduce((acc, curr) => acc + curr.total_day, 0)}
        </th>
      ))}
      {kiranaData.map((kirana, index) => (
        <th className="tb-col" key={index}>
          {kirana.dal_weight_p_student * 2 * monthData.reduce((acc, curr) => acc + curr.no_of_students, 0) * monthData.reduce((acc, curr) => acc + curr.total_day, 0)}
        </th>
      ))}
      {kiranaData.map((kirana, index) => (
        <th className="tb-col" key={index}>
          {kirana.sabji_weight_p_student * 2 * monthData.reduce((acc, curr) => acc + curr.no_of_students, 0) * monthData.reduce((acc, curr) => acc + curr.total_day, 0)}
        </th>
      ))}
      {kiranaData.map((kirana, index) => (
        <th className="tb-col" key={index}>
          {kirana.namak_weight_p_student * 2 * monthData.reduce((acc, curr) => acc + curr.no_of_students, 0) * monthData.reduce((acc, curr) => acc + curr.total_day, 0)}
        </th>
      ))}
      {kiranaData.map((kirana, index) => (
        <th className="tb-col" key={index}>
          {kirana.lakdi_weight_p_student * 2 * monthData.reduce((acc, curr) => acc + curr.total_day, 0)}
        </th>
      ))}
      {kiranaData.map((kirana, index) => (
        <th className="tb-col" key={index}>
          {kirana.mirchi_weight_p_student * 2 * monthData.reduce((acc, curr) => acc + curr.no_of_students, 0) * monthData.reduce((acc, curr) => acc + curr.total_day, 0)}
        </th>
      ))}
      {kiranaData.map((kirana, index) => (
        <th className="tb-col" key={index}>
          {kirana.haldi_weight_p_student * 2 * monthData.reduce((acc, curr) => acc + curr.no_of_students, 0) * monthData.reduce((acc, curr) => acc + curr.total_day, 0)}
        </th>
      ))}
      {kiranaData.map((kirana, index) => (
        <th className="tb-col" key={index}>
          {kirana.dhaniya_weight_p_student * 2 * monthData.reduce((acc, curr) => acc + curr.no_of_students, 0) * monthData.reduce((acc, curr) => acc + curr.total_day, 0)}
        </th>
      ))}
      {kiranaData.map((kirana, index) => (
        <th className="tb-col" key={index}>
          {kirana.Shakkar_weight_p_student * 2 * monthData.reduce((acc, curr) => acc + curr.no_of_students, 0) * monthData.reduce((acc, curr) => acc + curr.total_day, 0)}
        </th>
      ))}
      {kiranaData.map((kirana, index) => (
        <th className="tb-col" key={index}>
          {kirana.tel_weight_p_student * 2 * monthData.reduce((acc, curr) => acc + curr.no_of_students, 0) * monthData.reduce((acc, curr) => acc + curr.total_day, 0)}
        </th>
      ))}
      {kiranaData.map((kirana, index) => (
        <th className="tb-col" key={index}>
          {kirana.Rai_weight_p_student * 2 * monthData.reduce((acc, curr) => acc + curr.no_of_students, 0) * monthData.reduce((acc, curr) => acc + curr.total_day, 0)}
        </th>
      ))}
      {kiranaData.map((kirana, index) => (
        <th className="tb-col" key={index}>
          {kirana.kadimirchi_weight_p_student * 2 * monthData.reduce((acc, curr) => acc + curr.no_of_students, 0) * monthData.reduce((acc, curr) => acc + curr.total_day, 0)}
        </th>
      ))}
    </tr>
    </tbody>
  );
  const handleDownloadPDF = async () => {
    if (!tableRef.current) {
      console.error('Table reference not found');
      return;
    }
    try {
      const canvas = await html2canvas(tableRef.current);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgWidth = 208;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(`Khadyan_panji_${monthData[0]?.selected_month}_${monthData[0]?.year}.pdf`);
    } catch (error) {
      console.error('Error converting table to PDF:', error);
    }
  };
  

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          <Fixed_header />
          <div className="nk-content">
            <div className="container">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head">
                    <div className="nk-block-head-between flex-wrap gap g-2">
                      <div className="nk-block-head-content">
       
                        <h2 className="nk-block-title">Khayan stak panji mah {monthData[0]?.selected_month} year {monthData[0]?.year}</h2>
                      </div>
                      <Make_month/>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="card">
                      <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div className="dataTable-container table-responsive">
                          <table className="datatable-init table dataTable-table" data-nk-container="table-responsive">
                            <thead className="table-light">
                              <TableHeader kiranaItems={kiranaData} />
                            </thead>
                            <tbody>{tableRows}</tbody>
                            {totalRow}
                          </table>
                        </div>
                        <div className="dataTable-bottom"></div>
                      </div>
                    </div>
                  </div>
                  <Breakfast_table/>
                </div>
                <button onClick={handleDownloadPDF}>Download PDF</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Khadyan_panji_new;
