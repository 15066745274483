import React, { useState } from 'react';

function MakeMonth() {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 10; // Change the range if needed
  const endYear = currentYear + 10; // Change the range if needed
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    try {
      const response = await fetch('https://api.rox-tech.com/ashram/make_month.php', {
        method: 'POST',
        body: formData
      });

      const data = await response.json();

      if (data.success) {
        alert('Month added successfully');
        closeModal();
      } else {
        alert('Failed to add month');
      }
    } catch (error) {
        alert('Month added successfully');
        closeModal();
        window.location.reload();
    }
  };

  return (
    <div>
      <div className="nk-block-head-content">
        <ul className="d-flex">
          <li>
          </li>
          <li>
            <button className="btn btn-primary d-none d-md-inline-flex" onClick={openModal}>+<span>Add Month</span></button>
          </li>
        </ul>
      </div>

      {showModal && (
        <div className="modal fade show" id="addUserModal" tabIndex="-1" aria-labelledby="addUserModalLabel" aria-modal="true" role="dialog" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="addUserModalLabel">Add Month</h4>
                <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="firstname" className="form-label">Month</label>
                        <div className="form-control-wrap">
                          <select name='selected_month' id="month" className="form-control">
                            {months.map((month, index) => (
                              <option key={index} value={month}>{month}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="lastname" className="form-label">Year</label>
                        <div className="form-control-wrap">
                          <select name='year' id="year" className="form-control">
                            {years.map((year, index) => (
                              <option key={index} value={year}>{year}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="email" className="form-label">No of Students</label>
                        <div className="form-control-wrap">
                          <input type="number" name='no_of_students' className="form-control" placeholder="No of Students" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="status" className="form-label">Total Days this Month</label>
                        <div className="form-control-wrap">
                          <input name='total_day' type="number" className="form-control" placeholder="Total Days this Month" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label htmlFor="role" className="form-label">Note</label>
                        <div className="form-control-wrap">
                          <textarea name='note' rows={3} type="number" className="form-control" placeholder="Optional" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="d-flex gap g-2">
                        <div className="gap-col">
                          <button className="btn btn-primary" type="submit">Add Month</button>
                        </div>
                        <div className="gap-col">
                          <input type='hidden' name='Ashram_id' value={localStorage.getItem('Ashram_id')} />
                          <button type="button" className="btn border-0" onClick={closeModal}>Discard</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MakeMonth;
