import React, { useState, useEffect } from "react";
import Sidebar from "../components/side_bar";
import Fixed_header from "../components/fixed_header";
import { Link } from "react-router-dom";

const StudentList = () => {
  const [students, setStudents] = useState([]);
  const [activeModal, setActiveModal] = useState(null);

  useEffect(() => {
    // Fetch student data from the API when the component mounts
    fetchStudents();
  }, []); // Empty dependency array ensures the effect runs only once after mounting

  const fetchStudents = async () => {
    try {
      const response = await fetch(
        'https://api.rox-tech.com/ashram/get_students.php'
      );
      if (response.ok) {
        const data = await response.json();
        setStudents(data); // Update state with fetched student data
      } else {
        console.error('Failed to fetch students');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          <Fixed_header />
          <div className="nk-content">
            <div className="container">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block">
                    <div className="row g-gs">
                      {students.map(student => (
                        <div
                          key={student.id}
                          className="col-sm-6 col-xl-4 col-xxl-3"
                        >
                          <div className="card text-center h-100">
                            <div className="card-body">
                              <div className="media media-xxl media-middle media-circle">
                                <img
                                  src={`https://api.rox-tech.com/ashram/${student.photo}`}
                                  alt="user"
                                />
                              </div>
                              <div className="mt-1 mb-4">
                                <a
                                  href="@@/URL/user-manage/user-profile.html"
                                  className="mb-1 h5"
                                >
                                  {student.student_name}
                                </a>
                                <div className="small">{student.class}st class</div>
                              </div>
                              <div className="row g-gs justify-content-center">
                                <div className="col-5">
                                  <div
                                    className="nav-pills-border gap g-3 nav nav-pills"
                                    role="tablist"
                                  >
                                    <div className="nav-item col-12">
                                      <button
                                        className="nav-link active"
                                        type="button" className="btn btn-primary"
        data-toggle="modal"  data-target={`#exampleModal${student.id}`}
>
                                      
                                        Call 
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-5">
                                  <div
                                    className="nav-pills-border gap g-3 nav nav-pills"
                                    role="tablist"
                                  >
                                    <div className="nav-item col-12">
                                      <Link
                                        role="tab"
                                        data-rr-ui-event-key="tabOne"
                                        id={`react-aria${student.id}-tab-tabOne`}
                                        aria-controls={`react-aria${student.id}-tabpane-tabOne`}
                                        aria-selected="true"
                                        className="nav-link active"
                                        tabIndex="0"
                                        to="/student"
                                      >
                                        Profile
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown position-absolute top-0 end-0 p-3">
                              <a
                                href="#"
                                className="btn btn-sm btn-icon btn-zoom"
                                data-bs-toggle="dropdown"
                              >
                                <em className="icon ni ni-more-v"></em>
                              </a>
                              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                                <div className="dropdown-content py-1">
                                  <ul className="link-list link-list-hover-bg-primary link-list-md">
                                    <li>
                                      <a href="https://html.nioboard.themenio.com/user-manage/user-edit.html">
                                        <em className="icon ni ni-edit"></em>
                                        <span>Edit</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="https://html.nioboard.themenio.com/user-manage/user-edit.html">
                                        <em className="icon ni ni-trash"></em>
                                        <span>Delete</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="https://html.nioboard.themenio.com/user-manage/user-profile.html">
                                        <em className="icon ni ni-eye"></em>
                                        <span>View Details</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* Modal */}
                  {students.map(student => (
                    <div
                      key={student.id} className="modal fade"
        id={`exampleModal${student.id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
                      <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id={`exampleModalLongTitle${student.id}`}>
                              Modal title
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                          <Link to={`tel:${student.father_number}`}>{student.father_number}</Link><br></br>
                          <Link to={`tel:${student.mother_number}`}>{student.father_number}</Link>


                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                              
                            >
                              Close
                            </button>
                            <button type="button" className="btn btn-primary">
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentList;
