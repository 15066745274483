import React from 'react';

function Logout() {
    const handleLogout = () => {
        localStorage.removeItem('Ashram_id'); // Remove AshramId from localStorage
        window.location.href = '/login'; // Redirect to the login page
    };

    // Automatically log out when the component mounts
    React.useEffect(() => {
        handleLogout();
    }, []);

    return null; // No need to render anything
}

export default Logout;
